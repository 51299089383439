<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Products/List</h4>
          <div>
            <router-link :to="can('product-create') ? {name:'appProductCreate'} : '#'">
              <span :title="can('product-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-2">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="paginationOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <label>Product Category</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedProductCategory" class=""
                                :options="productCategoryOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <label>Type</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedType" class="" :options="typeOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <label>Activation Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="" :options="statusOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>

              <div class="col-12 col-sm-6 col-md-2">
                <label>Return Appointment Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedReturnAppointmentStatus" class="" :options="statusOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>

              <div class="col-12 col-sm-6 col-md-2">
                <label>Customer Portal Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedCustomerPortalStatus" class="" :options="statusOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getProductsParams.name_code_query" class="form-control search-product-input-element"
                       type="text"
                       placeholder="Search by name or code">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyProductFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'30%'}">Name & Category</th>
                    <th class="position-relative" :style="{width:'10%'}">Code</th>
                    <th class="position-relative" :style="{width:'10%'}">Type</th>
                    <th class="position-relative" :style="{width:'10%'}">Pre Order</th>
                    <th class="position-relative" :style="{width:'10%'}">Return Appointment Status</th>
                    <th class="position-relative" :style="{width:'10%'}">Customer Portal Status</th>
                    <th class="position-relative" :style="{width:'10%'}">Status<br>Source</th>
                    <th class="position-relative" :style="{width:'5%'}">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(product, index) in products" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="text-capitalize">{{ product.name }}</span><br>
                      <span class="badge"
                            :class="product.productCategory?.name ? 'badge-light-primary' : 'badge-danger'">{{
                          product.productCategory?.name ?? 'Not Found'
                        }}</span>
                    </td>
                    <td>{{ product.code }}</td>
                    <td>{{ product.type }}</td>
                    <td>
                      <button class="btn"
                              :disabled="!can('product-update')"
                              :title="can('product-update') ? `${product.pre_order} | Click to update` : `${product.pre_order} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#productPreOrderStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: product.id, existingData: {pre_order: product.pre_order}}">
                        <span class="badge"
                              :class="product.pre_order === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ product.pre_order }}
                        </span>
                      </button>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('product-update')"
                              :title="can('product-update') ? `${product.return_appointment_status} | Click to update` : `${product.return_appointment_status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#productReturnAppointmentStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: product.id, existingData: {return_appointment_status: product.return_appointment_status}}">
                        <span class="badge"
                              :class="product.return_appointment_status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ product.return_appointment_status }}
                        </span>
                      </button>
                    </td>

                    <td>
                      <button class="btn"
                              :disabled="!can('product-update')"
                              :title="can('product-update') ? `${product.customer_portal_status} | Click to update` : `${product.customer_portal_status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#productCustomerPortalStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: product.id, existingData: {customer_portal_status: product.customer_portal_status}}">
                        <span class="badge"
                              :class="product.customer_portal_status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ product.customer_portal_status }}
                        </span>
                      </button>
                    </td>


                    <td>
                      <button class="btn"
                              :disabled="!can('product-update')"
                              :title="can('product-update') ? `${product.status} | Click to update` : `${product.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#productStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: product.id, existingData: {status: product.status}}">
                        <span class="badge"
                              :class="product.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ product.status }}
                        </span>
                      </button>
                      <span class="badge badge-pill badge-light-danger">{{ product.source }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link :to="can('product-view') ? {name:'appProductView',params:{id:product.id}} : '#'"
                                     class=" cursor-pointer view-edit-delete-icon" :style="{height:'18px'}"
                                     :title="can('product-view') ? 'View' : 'View Forbidden'">
                          <i class='bx bx-show'></i>
                        </router-link>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <button class="dropdown-item"
                                    :disabled="!can('product-delete')"
                                    :title="can('product-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-toggle="modal" data-target="#productDeleteAlertModal"
                                    @click="this.modelIdForDelete = product.id">Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyProductFilter"/>
              </div>
            </div>
          </div>
        </div>
        <product-status-update-alert-modal :model-for-updating="modelForUpdating" model-name="product"
                                           modal-name="productStatusUpdateAlertModal"
                                           @confirmModelUpdating="productStatusUpdating"/>

        <product-pre-order-status-update-alert-modal :model-for-updating="modelForUpdating" model-name="product"
                                                     modal-name="productPreOrderStatusUpdateAlertModal"
                                                     @confirmModelUpdating="productPreOrderStatusUpdating"/>

        <product-return-appointment-status-update-alert-modal :model-for-updating="modelForUpdating"
                                                              model-name="product"
                                                              modal-name="productReturnAppointmentStatusUpdateAlertModal"
                                                              @confirmModelUpdating="productReturnAppointmentStatusUpdating"/>
        <product-customer-portal-status-update-alert-modal :model-for-updating="modelForUpdating"
                                                              model-name="product"
                                                              modal-name="productCustomerPortalStatusUpdateAlertModal"
                                                              @confirmModelUpdating="productCustomerPortalStatusUpdating"/>

        <product-delete-alert-modal :model-id="modelIdForDelete" model-name="product"
                                    modal-name="productDeleteAlertModal"
                                    @confirmModelDeletion="onConfirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout";
import ProductDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ProductStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import ProductPreOrderStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import ProductReturnAppointmentStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import productCustomerPortalStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
// core packages
import {mapActions, mapGetters} from "vuex";
// mixins
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "ProductList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ProductDeleteAlertModal,
    ListPagination,
    ProductStatusUpdateAlertModal,
    ProductPreOrderStatusUpdateAlertModal,
    ProductReturnAppointmentStatusUpdateAlertModal,
    productCustomerPortalStatusUpdateAlertModal,
  },
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },

      getProductCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },

      getProductsParams: {
        name_code_query: '',
        product_category_id: '',
        type: '',
        status: '',
        return_appointment_status: '',
        customer_portal_status: '',
        paginate: 1,
        pagination: '',
        order_by_id: 'DESC',
        page: '',
        with_relation: ['productCategory', 'addedBy'],
      },

      selectedPagination: {
        value: '',
        name: 'Default'
      },

      selectedProductCategory: {
        value: '',
        name: 'Any'
      },

      selectedType: {
        value: '',
        name: 'Any'
      },

      selectedStatus: {
        value: '',
        name: 'Any'
      },

      selectedReturnAppointmentStatus: {
        value: '',
        name: 'Any'
      },

      selectedCustomerPortalStatus: {
        value: '',
        name: 'Any'
      },

      modelForUpdating: {},
      modelIdForDelete: '',


    }
  },

  watch: {
    selectedPagination(pagination) {
      this.getProductsParams.pagination = pagination.value;
    },

    selectedProductCategory(selectedProductCategory) {
      this.getProductsParams.product_category_id = selectedProductCategory.value;
    },

    selectedType(selectedType) {
      this.getProductsParams.type = selectedType.value;
    },

    selectedStatus(status) {
      this.getProductsParams.status = status.value;
    },

    selectedReturnAppointmentStatus(status) {
      this.getProductsParams.return_appointment_status = status.value;
    },

    selectedCustomerPortalStatus(status) {
      this.getProductsParams.customer_portal_status = status.value;
    },


  },

  computed: {
    ...mapGetters({
      products: 'appProducts/products',
      paginateLinks: 'appProducts/paginateLinks',
    }),
    paginationOptions() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },

    productCategoryOptions() {
      let productCategories = this.$store.getters['appProductCategories/productCategories'].map((productCategory) => {
        let id = productCategory.id;
        let name = productCategory.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'Any'}, ...productCategories];
    },

    typeOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Hardware'
        },
        {
          value: 1,
          name: 'Software'
        },
      ];
    },

    statusOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getProductCategories: 'appProductCategories/getProductCategories',
      getProducts: 'appProducts/getProducts',
      getProduct: 'appProducts/getProduct',

      putProductOnList: 'appProducts/putProductOnList',

      deleteProductOnList: 'appProducts/deleteProductOnList',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getProductCategoryList() {
      await this.getProductCategories(this.getProductCategoriesParams);
    },

    async getProductList() {
      await this.getProducts(this.getProductsParams).then(async (response) => {
        if (response.message && response.status !== 200) {
          await this.showToastMessage(response);
        }
      });
    },

    async applyProductFilter(pageNumber) {
      this.getProductsParams.page = pageNumber;
      await this.loader(true);
      await this.getProductList(this.getProductsParams);
      await this.loader(false);
    },

    async productStatusUpdating(productStatusUpdating) {
      await this.productStatusUpdatingOnList(productStatusUpdating);
    },
    async productPreOrderStatusUpdating(productPreOrderStatusUpdating) {
      await this.productPreOrderStatusUpdatingOnList(productPreOrderStatusUpdating);
    },
    async productReturnAppointmentStatusUpdating(productReturnAppointmentStatusUpdating) {
      await this.productReturnAppointmentStatusUpdatingOnList(productReturnAppointmentStatusUpdating);
    },
    async productCustomerPortalStatusUpdating(productCustomerPortalStatusUpdating) {
      await this.productCustomerPortalStatusUpdatingOnList(productCustomerPortalStatusUpdating);
    },

    async productStatusUpdatingOnList(productStatusUpdating) {
      let dataObj = {
        id: productStatusUpdating.modelForUpdating.modelId,
        data: {
          status: productStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putProductOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Product status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async productPreOrderStatusUpdatingOnList(productPreOrderStatusUpdating) {
      let dataObj = {
        id: productPreOrderStatusUpdating.modelForUpdating.modelId,
        data: {
          pre_order: productPreOrderStatusUpdating.modelForUpdating.existingData.pre_order === "Active" ? 0 : 1,
        }
      }

      this.putProductOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Product pre order status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async productReturnAppointmentStatusUpdatingOnList(productReturnAppointmentStatusUpdating) {
      console.log('ABC', productReturnAppointmentStatusUpdating)
      let dataObj = {
        id: productReturnAppointmentStatusUpdating.modelForUpdating.modelId,
        data: {
          return_appointment_status: productReturnAppointmentStatusUpdating.modelForUpdating.existingData.return_appointment_status === "Active" ? 0 : 1,
        }
      }

      this.putProductOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Product return appointment status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async productCustomerPortalStatusUpdatingOnList(productCustomerPortalStatusUpdating) {
      console.log('ABC', productCustomerPortalStatusUpdating);
      let dataObj = {
        id: productCustomerPortalStatusUpdating.modelForUpdating.modelId,
        data: {
          customer_portal_status: productCustomerPortalStatusUpdating.modelForUpdating.existingData.customer_portal_status === "Active" ? 0 : 1,
        }
      }

      this.putProductOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Product Customer Panel status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleProductOnLIst(confirmModelDeletion.modelId);
    },

    async deleteSingleProductOnLIst(id) {
      this.deleteProductOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Product deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getProductCategoryList();
    await this.getProductList();
    await this.loader(false);
  }
}
</script>

<style scoped>

</style>